<form
  [formGroup]="form"
  autocomplete="off"
  class="overflow-x-hidden"
  (keydown.enter)="apply()"
>
  <!--  <div class="page-title pb-3 border-b flex items-center">-->
  <!--    <h2 class="title m-0">{{'Table filter' | translate }}</h2>-->
  <!--  </div>-->
  <div class="flex sm:flex-row sm:gap-4 gap-2 w-100 row-wrap">
    <!--///////////////// Name filter ///////////////////////////-->
    <ng-container *ngIf="isName">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Name' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'Name' | translate }}"
          formControlName="name"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Arabic Name filter ///////////////////////////-->
    <ng-container *ngIf="isArabicName">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Arabic name' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'Arabic name' | translate }}"
          formControlName="arabic_name"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Arabic Name filter ///////////////////////////-->
    <ng-container *ngIf="isEnglishName">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'English name' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'English name' | translate }}"
          formControlName="english_name"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// File Number filter ///////////////////////////-->
    <ng-container *ngIf="isFileNum">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'File number' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'File number' | translate }}"
          formControlName="file_num"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// From To required filter ///////////////////////////-->
    <ng-container *ngIf="isFromDateRequired">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        style="display: inline"
      >
        <mat-label>{{ 'From' | translate }}</mat-label>
        <input
          [max]="today"
          matInput
          readonly
          [matDatepicker]="startDate"
          placeholder="{{ 'From' | translate }}"
          formControlName="date_from_required"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Date To required filter ///////////////////////////-->
    <ng-container *ngIf="isToDateRequired">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        style="display: inline"
      >
        <mat-label>{{ 'To' | translate }}</mat-label>
        <input
          [max]="today"
          [min]="form.value.date_from_required"
          matInput
          readonly
          [matDatepicker]="endDate"
          placeholder="{{ 'To' | translate }}"
          formControlName="date_to_required"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>
      <!--
      <small
        *ngIf="form.controls['date_to_required'].errors && !(form.controls['date_from_required'].hasError('required')) && form.controls['date_from_required'].touched"
        class="gene-block warn-text">
        {{'You must include a valid to date' | translate}}
      </small>
      -->
    </ng-container>
    <!--///////////////// Account type filter ///////////////////////////-->
    <ng-container *ngIf="isAccountType">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Account type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="account_type"
          [matAutocomplete]="account_type"
        />
        <mat-autocomplete
          #account_type="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('account_type')"
        >
          <ng-container
            *ngFor="let option of accountTypesFilteredOptions | async"
          >
            <mat-option *ngIf="roles?.includes(option?.role)" [value]="option">
              <span class="body-1">{{ option.name | translate }}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
        <button class="text-secondary" mat-icon-button matSuffix type="button">
          <mat-icon svgIcon="mat:arrow_drop_down"></mat-icon>
        </button>
        <mat-error
          *ngIf="
            form.get('account_type').hasError('wrong') &&
            form.controls['account_type'].touched
          "
        >
          {{ 'You need to choose a valid account type' | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Date From filter ///////////////////////////-->
    <ng-container *ngIf="isFromDate">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        style="display: inline"
      >
        <mat-label>{{ 'From' | translate }}</mat-label>
        <input
          [max]="today"
          matInput
          readonly
          [matDatepicker]="startDate"
          placeholder="{{ 'From' | translate }}"
          formControlName="date_from"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Date To filter ///////////////////////////-->
    <ng-container *ngIf="isToDate">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        style="display: inline"
      >
        <mat-label>{{ 'To' | translate }}</mat-label>
        <input
          [max]="today"
          [min]="form.value.date_from"
          matInput
          readonly
          [matDatepicker]="endDate"
          placeholder="{{ 'To' | translate }}"
          formControlName="date_to"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Civil ID filter ///////////////////////////-->
    <ng-container *ngIf="isCivilId">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Civil number' | translate }}</mat-label>
        <input
          type="number"
          matInput
          placeholder="{{ 'Civil number' | translate }}"
          formControlName="civil_ID"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Username filter ///////////////////////////-->
    <ng-container *ngIf="isUsername">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Username' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Username' | translate }}"
          formControlName="username"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Email filter ///////////////////////////-->
    <ng-container *ngIf="isEmail">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'E-Mail' | translate }}</mat-label>
        <input formControlName="email" matInput />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Phone filter ///////////////////////////-->
    <ng-container *ngIf="isPhone">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Phone number' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Phone number' | translate }}"
          formControlName="phone_number"
          autocomplete="tel"
          type="tel"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Passport filter ///////////////////////////-->
    <ng-container *ngIf="isPassport">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Passport number' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Passport number' | translate }}"
          formControlName="passport"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Review Date filter ///////////////////////////-->
    <ng-container *ngIf="isReviewDate">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Review date' | translate }}</mat-label>
        <input
          matInput
          readonly
          [matDatepicker]="endDate"
          placeholder="{{ 'Review date' | translate }}"
          formControlName="review_date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #endDate></mat-datepicker>
      </mat-form-field>
    </ng-container>

    <!--///////////////// Recipe Number filter ///////////////////////////-->
    <ng-container *ngIf="isReceiptNum">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Receipt number' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Receipt number' | translate }}"
          formControlName="receipt_Num"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Service filter ///////////////////////////-->
    <ng-container *ngIf="isService">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllService')"
      >
        <mat-label>{{ 'Service' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="service"
          [matAutocomplete]="auto22"
        />
        <mat-autocomplete
          #auto22="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('service')"
        >
          <mat-option
            *ngFor="let option of servicesFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Payment Method filter ///////////////////////////-->
    <ng-container *ngIf="isPaymentMethod">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllPaymentMethod')"
      >
        <mat-label>{{ 'Payment method' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="payment_method"
          [matAutocomplete]="auto16"
        />
        <mat-autocomplete
          #auto16="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('payment_method')"
        >
          <mat-option
            *ngFor="let option of paymentMethodsFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// User filter ///////////////////////////-->
    <ng-container *ngIf="isUser">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllUsers')"
      >
        <mat-label>{{ 'User' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="user"
          [matAutocomplete]="auto62"
        />
        <mat-autocomplete
          #auto62="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('user')"
        >
          <ng-container
            *ngFor="let option of personDataFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Service filter ///////////////////////////-->
    <ng-container *ngIf="isMemberShipNum">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Membership num' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Membership num' | translate }}"
          formControlName="membership_num"
        />
      </mat-form-field>
    </ng-container>

    <!--///////////////// Action Code filter ///////////////////////////-->
    <ng-container *ngIf="isActionCode">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Action Code' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'Action Code' | translate }}"
          formControlName="action_code"
        />
      </mat-form-field>
    </ng-container>

    <!--///////////////// Company File Number filter ///////////////////////////-->
    <ng-container *ngIf="isCompanyFileNumber">
      <mat-form-field
        *ngIf="isCompanyUser"
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
      >
        <mat-label>{{ 'Company file number' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Company file number' | translate }}"
          formControlName="company_file_num"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Date filter ///////////////////////////-->
    <ng-container *ngIf="isDate">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Date' | translate }}</mat-label>
        <input
          matInput
          readonly
          [matDatepicker]="startDate"
          placeholder="{{ 'Date' | translate }}"
          formControlName="date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Kuwit phone filter ///////////////////////////-->
    <ng-container *ngIf="isKuwitPhoneNumber">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Kuwait phone number' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Kuwait phone number' | translate }}"
          formControlName="kuwit_phone_num"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// First name filter ///////////////////////////-->
    <ng-container *ngIf="isFirstName">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'First name' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'First name' | translate }}"
          formControlName="first_name"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Second name filter ///////////////////////////-->
    <ng-container *ngIf="isSecondName">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Second name' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Second name' | translate }}"
          formControlName="second_name"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Third filter ///////////////////////////-->
    <ng-container *ngIf="isThirdName">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Third name' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Third name' | translate }}"
          formControlName="third_name"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Family name filter ///////////////////////////-->
    <ng-container *ngIf="isFamilyName">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Family name' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'Family name' | translate }}"
          formControlName="family_name"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Classification Types filter ///////////////////////////-->
    <ng-container *ngIf="isClassificationType">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Classification type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="classification_type"
          [matAutocomplete]="classification_type"
        />
        <mat-autocomplete
          #classification_type="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('classification_type')"
        >
          <ng-container
            *ngFor="
              let option of classificationCertTypesFilteredOptions | async
            "
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// evaluation Types filter ///////////////////////////-->
    <ng-container *ngIf="isEvalutionType">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllEvaluationType')"
      >
        <mat-label>{{ 'Evaluation type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="evaluation_type"
          [matAutocomplete]="auto52"
        />
        <mat-autocomplete
          #auto52="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('evaluation_type')"
        >
          <mat-option
            *ngFor="let option of evalTypeFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// evaluation Date filter ///////////////////////////-->
    <ng-container *ngIf="isEvaluationDate">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Select Evaluation date' | translate }}</mat-label>
        <input
          matInput
          readonly
          [matDatepicker]="startDate"
          placeholder="{{ 'Select Evaluation date' | translate }}"
          formControlName="evaluation_date"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
      </mat-form-field>
    </ng-container>
    <!--///////////////// profession filter ///////////////////////////-->
    <ng-container *ngIf="isProfession">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Select profession' | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'Select profession' | translate }}"
          formControlName="profession"
        >
          <ng-container *ngFor="let option of jobs">
            <mat-option [value]="option.id">{{ option.name }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <!--///////////////// is membership filter ///////////////////////////-->
    <ng-container *ngIf="isIsMembership">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Is membership?' | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'Is membership?' | translate }}"
          formControlName="isMembership"
        >
          <ng-container *ngFor="let option of yesOrNo">
            <mat-option [value]="option.id">{{ option.name }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <!--///////////////// NOC filter ///////////////////////////-->
    <ng-container *ngIf="isNOC">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'NOC' | translate }}</mat-label>
        <mat-select placeholder="{{ 'NOC' | translate }}" formControlName="noc">
          <ng-container *ngFor="let option of nocOptions">
            <mat-option [value]="option.id">{{ option.name }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Audit category filter ///////////////////////////-->
    <ng-container *ngIf="isCategoryName">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Category name' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'Name' | translate }}"
          formControlName="category_name"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Cashier User filter ///////////////////////////-->
    <ng-container *ngIf="isCashierUser">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllUsers')"
      >
        <mat-label>{{ 'Cashier user' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="cashier_user"
          [matAutocomplete]="auto25"
        />
        <mat-autocomplete
          #auto25="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('cashier_user')"
        >
          <ng-container
            *ngFor="let option of cashierUsersFilteredOptions | async"
          >
            <mat-option [value]="option">{{ option.name }}</mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Nationality filter ///////////////////////////-->
    <ng-container *ngIf="isNationality">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllNationality')"
      >
        <mat-label>{{ 'Nationality' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="nationality"
          [matAutocomplete]="auto19"
        />
        <mat-autocomplete
          #auto19="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('nationality')"
        >
          <ng-container
            *ngFor="let option of nationalitiesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Job filter ///////////////////////////-->
    <ng-container *ngIf="isJob">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllJobs')"
      >
        <mat-label>{{ 'Job' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="job"
          [matAutocomplete]="auto17"
        />
        <mat-autocomplete
          #auto17="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('job')"
        >
          <ng-container *ngFor="let option of jobsFilteredOptions | async">
            <mat-option [value]="option">{{ option.name }}</mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Evalution Status filter ///////////////////////////-->
    <ng-container *ngIf="isEvalutionStatus">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllEvaluationType')"
      >
        <mat-label>{{ 'Evaluation status' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="evaluation_status"
          [matAutocomplete]="auto20"
        />
        <mat-autocomplete
          #auto20="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('evaluation_status')"
        >
          <ng-container
            *ngFor="let option of evalStatusFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Residency Type filter ///////////////////////////-->
    <ng-container *ngIf="isResidencyType">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllResidencyType')"
      >
        <mat-label>{{ 'Residency type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="residency"
          [matAutocomplete]="auto55"
        />
        <mat-autocomplete
          #auto55="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('residency')"
        >
          <ng-container
            *ngFor="let option of residenciesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Status Type Type filter ///////////////////////////-->
    <ng-container *ngIf="isStatusType">
      <mat-form-field
        class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllStatusType')"
      >
        <mat-label>{{ 'Status type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="status_type"
          [matAutocomplete]="auto61"
        />
        <mat-autocomplete
          #auto61="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('status_type')"
        >
          <mat-option
            *ngFor="let option of statusTypesFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <!--///////////////// Audit data filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'auditData'">
      <div class="card flex-auto">
        <div class="px-6 py-4 flex flex-col">
          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field
              class="flex-1 w-100"
              appearance="fill"
              style="display: inline"
            >
              <mat-label>{{ 'Date from' | translate }}</mat-label>
              <input
                matInput
                readonly
                [matDatepicker]="action_datetime_from"
                placeholder="{{ 'Date from' | translate }}"
                formControlName="auditData_datetime_from"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="action_datetime_from"
              ></mat-datepicker-toggle>
              <mat-datepicker #action_datetime_from></mat-datepicker>
            </mat-form-field>
            <mat-form-field
              class="flex-1 w-100"
              appearance="fill"
              style="display: inline"
            >
              <mat-label>{{ 'Date to' | translate }}</mat-label>
              <input
                matInput
                readonly
                [matDatepicker]="action_datetime_to"
                placeholder="{{ 'Date to' | translate }}"
                formControlName="auditData_datetime_to"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="action_datetime_to"
              ></mat-datepicker-toggle>
              <mat-datepicker #action_datetime_to></mat-datepicker>
            </mat-form-field>

            <mat-form-field
              class="flex-1 w-100"
              appearance="fill"
              style="display: inline"
            >
              <mat-label>{{
                'User number or civil id or passport' | translate
              }}</mat-label>
              <input
                matInput
                placeholder="{{ 'File number' | translate }}"
                formControlName="auditData_user_id"
              />
            </mat-form-field>
          </div>

          <div class="flex flex-col sm:flex-row sm:gap-4">
            <mat-form-field
              class="flex-1 w-100"
              appearance="fill"
              style="display: inline"
            >
              <mat-label>{{
                'File number or civil id or passport' | translate
              }}</mat-label>
              <input
                matInput
                placeholder="{{
                  'File number or civil id or passport' | translate
                }}"
                formControlName="auditData_data_id"
              />
            </mat-form-field>
            <mat-form-field
              class="flex-1 w-100"
              appearance="fill"
              style="display: inline"
            >
              <mat-label>{{ 'Event' | translate }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="auditData_category"
                [matAutocomplete]="auto1"
              />
              <mat-autocomplete
                #auto1="matAutocomplete"
                [displayWith]="displayFn"
                (opened)="handlerAutocomplete('audit_category')"
              >
                <mat-option
                  *ngFor="
                    let auditCategoryOption of auditCategoryFilteredOptions
                      | async
                  "
                  [value]="auditCategoryOption"
                >
                  {{ auditCategoryOption.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field
              class="flex-1 w-100"
              appearance="fill"
              style="display: inline"
            >
              <mat-label>{{ 'Event type' | translate }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="auditData_category_method"
                [matAutocomplete]="auto2"
              />
              <mat-autocomplete
                #auto2="matAutocomplete"
                [displayWith]="displayFn"
                (opened)="handlerAutocomplete('audit_category_method')"
              >
                <mat-option
                  *ngFor="
                    let auditCategoryMethodsOption of auditCategoryMethodsFilteredOptions
                      | async
                  "
                  [value]="auditCategoryMethodsOption"
                >
                  {{ auditCategoryMethodsOption.describtion }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
    <!--///////////////// Company Name filter ///////////////////////////-->
    <ng-container *ngIf="isCompanyName">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Company Name' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'Company Name' | translate }}"
          formControlName="company_name"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Company File Number filter ///////////////////////////-->
    <ng-container *ngIf="isCompanyFileNum">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Company File Num' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'Company File Num' | translate }}"
          formControlName="company_file_number"
        />
      </mat-form-field>
    </ng-container>
    <!--///////////////// Notification data filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'notificationData'">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Arabic title' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'Arabic title' | translate }}"
          formControlName="notification_data_title_ar"
        />
      </mat-form-field>
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'English title' | translate }}</mat-label>
        <input
          type="text"
          matInput
          placeholder="{{ 'English title' | translate }}"
          formControlName="notification_data_title_en"
        />
      </mat-form-field>
    </ng-container>

    <!--/////////////////Chat messages filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'chatMessages'">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Select chat service' | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'Select chat service' | translate }}"
          formControlName="add_message_chat_service"
        >
          <ng-container *ngFor="let option of chatServices">
            <mat-option [value]="option.id">{{ option.name }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <!--///////////////// Select Month filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'classification_interviews'">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Select Month' | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'Select Month' | translate }}"
          formControlName="month_filter"
          (selectionChange)="selectMonth($event)"
        >
          <ng-container *ngFor="let option of monthFilter">
            <mat-option [value]="option">{{ option.name }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <!--/////////////////Basic data report filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'basicDataReport'">
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllCertificateType')"
      >
        <mat-label>{{ 'Education type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_education_type"
          [matAutocomplete]="auto3"
        />
        <mat-autocomplete
          #auto3="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="resetCertificate('type')"
          (opened)="handlerAutocomplete('basic_data_education_type')"
        >
          <mat-option
            *ngFor="let option of eduTypesFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllEduRating')"
      >
        <mat-label>{{ 'Rate' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_rate"
          [matAutocomplete]="auto4"
        />
        <mat-autocomplete
          #auto4="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="resetCertificate('rate')"
          (opened)="handlerAutocomplete('basic_data_rate')"
        >
          <mat-option
            *ngFor="let option of ratingsFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllCountry')"
      >
        <mat-label>{{ 'Country' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_country"
          [matAutocomplete]="auto5"
        />
        <mat-autocomplete
          #auto5="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="resetCertificate('country')"
          (opened)="handlerAutocomplete('basic_data_country')"
        >
          <mat-option
            *ngFor="let option of countriesFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetUniAndSchoolFilterd')"
      >
        <mat-label>{{ 'University' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_univesity"
          [matAutocomplete]="auto6"
        />
        <mat-autocomplete
          #auto6="matAutocomplete"
          [displayWith]="displayFn"
          (optionSelected)="schoolAnUniChanged()"
          (opened)="handlerAutocomplete('basic_data_univesity')"
        >
          <ng-container
            *ngFor="let option of universitiesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="
          roles.includes('GetAllSecAndCollageBySchoolsAndUni') &&
          !form.value.schoolAndUni?.isNoNeedSectors
        "
      >
        <mat-label>{{ 'College' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_colleg"
          [matAutocomplete]="auto7"
        />
        <mat-autocomplete
          #auto7="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('basic_data_colleg')"
        >
          <ng-container *ngFor="let option of collegesFilteredOptions | async">
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllProfessionByCollege')"
      >
        <mat-label>{{ 'Major specialization' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_prof"
          [matAutocomplete]="auto8"
        />
        <mat-autocomplete
          #auto8="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('basic_data_prof')"
        >
          <ng-container
            *ngFor="let option of professionsFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllSubProfessionByCollegeAndProf')"
      >
        <mat-label>{{ 'Minor specialization' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_sub_prof"
          [matAutocomplete]="auto9"
        />
        <mat-autocomplete
          #auto9="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('basic_data_sub_prof')"
        >
          <ng-container
            *ngFor="let option of subProfessionsFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllJobs')"
      >
        <mat-label>{{ 'Job' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_job"
          [matAutocomplete]="auto10"
        />
        <mat-autocomplete
          #auto10="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('basic_data_job')"
        >
          <ng-container *ngFor="let option of jobsFilteredOptions | async">
            <mat-option [value]="option">{{ option.name }}</mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllGender')"
      >
        <mat-label>{{ 'Gender' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_gender"
          [matAutocomplete]="auto11"
        />
        <mat-autocomplete
          #auto11="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('basic_data_gender')"
        >
          <mat-option
            *ngFor="let option of gendersFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllStatusType')"
      >
        <mat-label>{{ 'Status' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_status"
          [matAutocomplete]="auto13"
        />
        <mat-autocomplete
          #auto13="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('basic_data_status')"
        >
          <ng-container
            *ngFor="let option of statusTypesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllNationality')"
      >
        <mat-label>{{ 'Nationality' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_nationality"
          [matAutocomplete]="auto14"
        />
        <mat-autocomplete
          #auto14="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('basic_data_nationality')"
        >
          <ng-container
            *ngFor="let option of nationalitiesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllResidencyType')"
      >
        <mat-label>{{ 'Residency type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="basic_data_residency"
          [matAutocomplete]="auto15"
        />
        <mat-autocomplete
          #auto15="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('basic_data_residency')"
        >
          <ng-container
            *ngFor="let option of residenciesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <div class="company-dropdown">
        <!--        https://www.npmjs.com/package/ng-multiselect-dropdown3-->
        <ng-multiselect-dropdown3
          id="multiDropDown"
          [placeholder]="'Company' | translate"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          (wheel)="wheel($event)"
          (scroll)="wheel($event)"
          (onFilterChange)="getRemoteCompanies($event)"
          formControlName="basic_data_company"
          class="custom-dropdown-class multiDropDown"
        >
        </ng-multiselect-dropdown3>
      </div>
    </ng-container>
    <!--/////////////////Reception cashier monthly report filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'receptionCashierMonthlyReport'">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'From' | translate }}</mat-label>
        <input
          matInput
          readonly
          [matDatepicker]="startDate"
          placeholder="{{ 'From' | translate }}"
          formControlName="reception_cashier_monthly_report_from"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #startDate
          (yearSelected)="
            chosenYearHandler($event, 'reception_cashier_monthly_report_from')
          "
          (monthSelected)="
            chosenMonthHandler(
              $event,
              startDate,
              'reception_cashier_monthly_report_from'
            )
          "
          startView="multi-year"
        ></mat-datepicker>
      </mat-form-field>
      <small
        *ngIf="
          form.controls['reception_cashier_monthly_report_from']?.hasError(
            'required'
          ) && form.controls['reception_cashier_monthly_report_from']?.touched
        "
        class="gene-block warn-text"
      >
        {{ 'You must enter a from date' | translate }}
      </small>
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'To' | translate }}</mat-label>
        <input
          matInput
          readonly
          [matDatepicker]="endDate"
          placeholder="{{ 'To' | translate }}"
          formControlName="reception_cashier_monthly_report_to"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #endDate
          (yearSelected)="
            chosenYearHandler($event, 'reception_cashier_monthly_report_to')
          "
          (monthSelected)="
            chosenMonthHandler(
              $event,
              endDate,
              'reception_cashier_monthly_report_to'
            )
          "
          startView="multi-year"
        ></mat-datepicker>
      </mat-form-field>
      <small
        *ngIf="
          form.controls['reception_cashier_monthly_report_to']?.hasError(
            'required'
          ) && form.controls['reception_cashier_monthly_report_to'].touched
        "
        class="gene-block warn-text"
      >
        {{ 'You must enter a to date' | translate }}
      </small>
      <small
        *ngIf="
          form.controls['reception_cashier_monthly_report_to']?.errors &&
          !form.controls['reception_cashier_monthly_report_to']?.hasError(
            'required'
          ) &&
          form.controls['reception_cashier_monthly_report_to']?.touched
        "
        class="gene-block warn-text"
      >
        {{ 'You must include a valid to date' | translate }}
      </small>
    </ng-container>

    <!--/////////////////Society cashier monthly report filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'dataStatisticsByCountryReport'">
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllJobs')"
      >
        <mat-label>{{ 'Job' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="data_statistics_by_country_job"
          [matAutocomplete]="auto37"
        />
        <mat-autocomplete
          #auto37="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('data_statistics_by_country_job')"
        >
          <ng-container *ngFor="let option of jobsFilteredOptions | async">
            <mat-option [value]="option">{{ option.name }}</mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllGender')"
      >
        <mat-label>{{ 'Gender' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="data_statistics_by_country_gender"
          [matAutocomplete]="auto38"
        />
        <mat-autocomplete
          #auto38="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('data_statistics_by_country_gender')"
        >
          <mat-option
            *ngFor="let option of gendersFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllCompany')"
      >
        <mat-label>{{ 'Company' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="data_statistics_by_country_company"
          [matAutocomplete]="auto39"
        />
        <mat-autocomplete
          #auto39="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('data_statistics_by_country_company')"
        >
          <mat-option
            *ngFor="let option of companiesFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllStatusType')"
      >
        <mat-label>{{ 'Status' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="data_statistics_by_country_status"
          [matAutocomplete]="auto40"
        />
        <mat-autocomplete
          #auto40="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('data_statistics_by_country_status')"
        >
          <ng-container
            *ngFor="let option of statusTypesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllNationality')"
      >
        <mat-label>{{ 'Nationality' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="data_statistics_by_country_nationality"
          [matAutocomplete]="auto41"
        />
        <mat-autocomplete
          #auto41="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="
            handlerAutocomplete('data_statistics_by_country_nationality')
          "
        >
          <ng-container
            *ngFor="let option of nationalitiesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllResidencyType')"
      >
        <mat-label>{{ 'Residency type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="data_statistics_by_country_residency"
          [matAutocomplete]="auto42"
        />
        <mat-autocomplete
          #auto42="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('data_statistics_by_country_residency')"
        >
          <ng-container
            *ngFor="let option of residenciesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <!--/////////////////Classification results report filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'classification_results'">
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllEvaluationType')"
      >
        <mat-label>{{ 'Classification type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="classification_type"
          [matAutocomplete]="classification_type"
        />
        <mat-autocomplete
          #classification_type="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('classificationTypes')"
        >
          <mat-option
            *ngFor="let option of classificationTypesOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <!--/////////////////Classification panels report filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'classification_panels'">
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllEvaluationType')"
      >
        <mat-label>{{ 'Classification type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="classification_type"
          [matAutocomplete]="classification_type_gl"
        />
        <mat-autocomplete
          #classification_type_gl="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('classificationTypes')"
        >
          <mat-option
            *ngFor="let option of classificationTypesOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllEvaluationType')"
      >
        <mat-label>{{ 'Evaluation type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="evaluation_type"
          [matAutocomplete]="evaluation_type_gl"
        />
        <mat-autocomplete
          #evaluation_type_gl="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('evaluation_type')"
        >
          <mat-option
            *ngFor="let option of evalTypeFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--/////////////////Classification panels report filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'evalution_panels'">
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="roles.includes('GetAllEvaluationType')"
      >
        <mat-label>{{ 'Evaluation type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="evaluation_type"
          [matAutocomplete]="evaluation_type_gl"
        />
        <mat-autocomplete
          #evaluation_type_gl="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('evaluation_type')"
        >
          <mat-option
            *ngFor="let option of evalTypeFilteredOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>

    <!--/////////////////classificationStamp filter ///////////////////////////-->
    <ng-container *ngIf="listName === 'classificationStamp'">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'Classification type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="stamps_type"
          [matAutocomplete]="stamps_type"
        />
        <mat-autocomplete
          #stamps_type="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('stamps_type')"
        >
          <ng-container
            *ngFor="let option of stampTypesFilteredOptions | async"
          >
            <mat-option [value]="option">
              {{ option.name }}
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <!--/////////////////classificationSuccessAndFailureReport and evaluationSuccessAndFailureReport filter ///////////////////////////-->
    <ng-container>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="
          roles.includes('GetAllEvaluationType') &&
          listName === 'classificationSuccessAndFailureReport'
        "
      >
        <mat-label>{{ 'Classification type' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="classification_type"
          [matAutocomplete]="classification_type_gl"
        />
        <mat-autocomplete
          #classification_type_gl="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('classificationTypes')"
        >
          <mat-option
            *ngFor="let option of classificationTypesOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="flex-auto max-w-[200px] w-25 w-sm-100 mrgn-b-md"
        *ngIf="
          roles.includes('GetAllEvaluationType') &&
          (listName === 'classificationSuccessAndFailureReport' ||
            listName === 'evaluationSuccessAndFailureReport')
        "
      >
        <mat-label>{{ 'Trying times' | translate }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="trying_times"
          [matAutocomplete]="trying_times"
        />
        <mat-autocomplete
          #trying_times="matAutocomplete"
          [displayWith]="displayFn"
          (opened)="handlerAutocomplete('trying_times')"
        >
          <mat-option
            *ngFor="let option of trying_timesFilterOptions | async"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="listName === 'classification_interviews'">
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'From' | translate }}</mat-label>
        <input
          matInput
          readonly
          [matDatepicker]="startDate"
          placeholder="{{ 'From' | translate }}"
          formControlName="date_from"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="startDate"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #startDate
          (yearSelected)="chosenYearHandler($event, 'date_from')"
          (monthSelected)="chosenMonthHandler($event, startDate, 'date_from')"
          startView="multi-year"
        ></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="flex-auto max-w-[300px] w-25 w-sm-100 mrgn-b-md">
        <mat-label>{{ 'To' | translate }}</mat-label>
        <input
          matInput
          readonly
          [matDatepicker]="endDate"
          placeholder="{{ 'To' | translate }}"
          formControlName="date_to"
          [min]="form.value.date_from"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="endDate"
        ></mat-datepicker-toggle>
        <mat-datepicker
          #endDate
          (yearSelected)="chosenYearHandler($event, 'date_to')"
          (monthSelected)="chosenMonthHandler($event, endDate, 'date_to')"
          startView="multi-year"
        ></mat-datepicker>
      </mat-form-field>
    </ng-container>
  </div>
  <div class="d-flex align-items-center justify-content-end py-2">
    <button
      type="button"
      class="mx-2"
      mat-raised-button
      (click)="resetForm()"
      color="warn"
    >
      {{ 'Reset' | translate }}
    </button>
    <button type="button" mat-raised-button (click)="apply()" color="primary">
      {{ 'Apply' | translate }}
    </button>
  </div>
</form>
