import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import {
  GetLanguage,
  getFilterValue,
  handleFilters,
  setMomentLocal,
} from '../shared/functions/shared-functions';
import { secureStorage } from '../shared/functions/secure-storage';
import { environment } from '../../environments/environment';
import { momentDateFormat } from '../shared/variables/variables';
import { PageTitleService } from '../core/components/page-title/page-title.service';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService,
    private pageTitleService: PageTitleService,
  ) {}

  generateExcel(
    data,
    values,
    header?,
    filtersData?,
    subHeaders?,
    subValues?,
    translateService?,
  ) {
    setMomentLocal();
    const translatedTitle = this.pageTitleService.getTitle();
    const title = secureStorage.getItem('title');
    const customHeaders = [...header];
    const customValues = [...values];
    let customSubHeaders = [];
    let customSubValues = [];
    let subHeader = [];
    const subData = [];
    const nestedTables = [
      /*'Evaluation details',*/ 'Actions on files status',
      'Actions on service request',
      'NOC audit',
      'Daily accounting',
      'Classification Report',
    ];
    if (nestedTables?.includes(title)) {
      customSubHeaders = [...subHeaders];
      customSubValues = [...subValues];
      // @ts-ignore
      if (title !== 'Classification Report') {
        subHeader = Object.values(translateService.instant(customSubHeaders));
      }
    }
    const prepare = [];
    data?.forEach((e) => {
      const row = [];
      const innerRow = [];
      customValues.forEach((value, index) => {
        row.push(e[value]?.toString() || ' - ');
      });
      if (nestedTables?.includes(title)) {
        const firstNested =
          title === 'Evaluation details'
            ? e?.evaluationLsit
            : title === 'Actions on files status'
              ? e?.mainSubObject
              : title === 'NOC audit'
                ? e?.auditEntriesModelDetails
                : e?.mainSubObject;
        firstNested?.forEach((sub) => {
          const subRow = [];
          customSubValues.forEach((value) => {
            // column.push((e[value]?.toString()?.replace(')', '(').replace('(', ')')) || ' - ');

            subRow.push(sub[value]?.toString() || ' - ');
          });
          innerRow.push(subRow);
        });
      }
      row.push(innerRow);
      prepare.push(row);
    });
    data = prepare;
    this.translateService.get(customHeaders).subscribe(async (res) => {
      // @ts-ignore
      Object.keys(res).map((el, index) => {
        const i = customHeaders.findIndex((x) => x == el);
        header[i] = res[el];
      });
      //header = Object.values(res);
      // Create workbook and worksheet
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(translatedTitle, {
        views: [{ rightToLeft: GetLanguage() === 'ar' }],
      });

      // Add Image
      const imageUrl = environment.mainLogo;
      const imageBytes = await fetch(imageUrl).then((response) =>
        response.arrayBuffer(),
      );
      const logo = workbook.addImage({
        // base64: logoFile.logoBase64,
        extension: 'png',
        buffer: imageBytes,
      });
      worksheet.addImage(logo, 'B2:B10');
      worksheet.mergeCells('B1:B10');

      const report_name = worksheet.getCell('C5');
      report_name.value = `${translatedTitle}  ${GetLanguage() === 'ar' ? momentDateFormat(new Date()) : momentDateFormat(new Date())}`;
      report_name.font = {
        name: 'Comic Sans MS',
        family: 4,
        size: 20,
        underline: 'none',
        bold: true,
        color: { argb: '#0c35f8' },
      };

      // Add Row and formatting
      worksheet.addRow([]);
      worksheet.addRow([]);

      // filters
      if (filtersData) {
        const filters = handleFilters(translateService, filtersData);
        let column1 = '';
        let column2 = '';
        let column3 = '';
        filters?.map((filter, index) => {
          const all = GetLanguage() === 'ar' ? 'الكل' : 'All';
          if (index <= filters?.length / 3) {
            column1 += `${filter?.key} :  ${getFilterValue(filter?.value) || all}              `;
          } else if (
            index > filters?.length / 3 &&
            index <= filters?.length / 1.5
          ) {
            column2 += `${filter?.key} :  ${getFilterValue(filter?.value) || all}              `;
          } else {
            column3 += `${filter?.key} :  ${getFilterValue(filter?.value) || all}              `;
          }
        });
        const column1Row = worksheet.getCell('B13');
        column1Row.value = column1;
        column1Row.font = {
          name: 'Comic Sans MS',
          family: 4,
          size: 20,
          underline: 'none',
          bold: true,
        };

        const column2Row = worksheet.getCell('B15');
        column2Row.value = column2;
        column2Row.font = {
          name: 'Comic Sans MS',
          family: 4,
          size: 20,
          underline: 'none',
          bold: true,
        };

        const column3Row = worksheet.getCell('B17');
        column3Row.value = column3;
        column3Row.font = {
          name: 'Comic Sans MS',
          family: 4,
          size: 20,
          underline: 'none',
          bold: true,
        };
      }

      // Add Row and formatting
      worksheet.addRow([]);
      // const date = GetLanguage() === 'ar' ? ': التاريخ' : 'Date : ';
      // const subTitleRow = worksheet.addRow(GetLanguage() === 'ar' ? [momentDateFormat(new Date())] : [momentDateFormat(new Date())])
      // subTitleRow.font = {name: 'Comic Sans MS', family: 4, size: 17, underline: 'none', bold: false}

      // Blank Row
      worksheet.addRow([]);

      // Add Header Row
      const headerRow = worksheet.addRow(header);
      headerRow.font = { family: 4, size: 14, underline: 'none', bold: true };

      // Cell Style : Fill and Border
      headerRow.eachCell((cell, colNumber) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '0c35f8' },
          bgColor: { argb: '0c35f8' },
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.font = {
          color: { argb: 'FFFFFF' },
          family: 4,
          size: 14,
          underline: 'none',
          bold: true,
        };
      });
      // worksheet.addRows(data);

      // Add Data and Conditional Formatting
      data.forEach((d, index) => {
        const nested = d[d.length - 1];
        d[d.length - 1] = null;
        const row = worksheet.addRow(d);
        row.font = { size: 16, family: 4, underline: 'none', bold: false };
        row.alignment = {
          horizontal: GetLanguage() === 'ar' ? 'right' : 'left',
        };
        if (nested) {
          //next condition :  for not adding subheader for total row Daily accounting report
          if (index !== 0 || title !== 'Daily accounting') {
            // Add Sub Header Row
            const subHeaderRow = worksheet.addRow(subHeader);
            subHeaderRow.font = {
              family: 3,
              size: 11,
              underline: 'none',
              bold: true,
            };
            // Cell Style : Fill and Border
            subHeaderRow.eachCell((cell, colNumber) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'f8820c' },
                bgColor: { argb: 'f8820c' },
              };
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
              cell.font = {
                color: { argb: 'FFFFFF' },
                family: 4,
                size: 14,
                underline: 'none',
                bold: true,
              };
            });
            nested?.forEach((sub) => {
              const subRow = worksheet.addRow(sub);
              subRow.font = {
                size: 12,
                family: 4,
                underline: 'none',
                bold: false,
              };
              row.alignment = {
                horizontal: GetLanguage() === 'ar' ? 'right' : 'left',
              };
            });
          }
        }
      });

      // worksheet.getColumn(3).width = 30;
      // worksheet.getColumn(4).width = 30;
      // worksheet.addRow([]);

      worksheet.columns.forEach((column) => {
        // const lengths = column.values.map(v => v?.toString().length);
        // const maxLength = Math.max(...lengths?.filter(v => typeof v === 'number')) + 5;
        // column.width = maxLength < 10 ? 10 : maxLength;
        column.width = 30;
      });
      // Footer Row
      // // let footerRow = worksheet.addRow(['This is system generated excel sheet.']);
      // // footerRow.getCell(1).fill = {
      // //     type: 'pattern',
      // //     pattern: 'solid',
      // //     fgColor: {argb: 'FFCCFFE5'}
      // // };
      // footerRow.getCell(1).border = {top: {style: 'thin'}, left: {style: 'thin'}, bottom: {style: 'thin'}, right: {style: 'thin'}}
      //
      // //Merge Cells
      // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

      // Generate Excel File with given name
      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fs.saveAs(
          blob,
          `${translatedTitle}-${momentDateFormat(new Date())}.xlsx`,
        );
      });
    });
  }
}
