import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './auth-service/ApiService ';
import { switchMap } from 'rxjs/operators';
import moment from 'moment';
import { encrypt } from '../shared/functions/shared-functions';
import { environment } from '../../environments/environment';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private apiService: ApiService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // Avoid intercepting the first API call

    if (
      req.url.includes('/api/ApplicationSetting/ServerTime') ||
      !req.url.includes(environment.apiUrl)
    ) {
      return next.handle(req);
    }

    // Fetch data from the first API
    return this.apiService.getFirstApiData().pipe(
      switchMap((firstApiData) => {
        // Modify the request with data from the first API
        const modifiedReq = req.clone({
          setHeaders: {
            MNS: this.getCustomHeaders(req, firstApiData),
          },
        });
        return next.handle(modifiedReq);
      }),
    );
  }

  getCustomHeaders(request, firstApiData) {
    // add secret property to all APIs to confirm our personality
    const msg = `${request.url}(${firstApiData})`;
    // const secret = btoa(CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(secret_key)).toString());
    const key = moment().locale('en').format('DD/MM/YYYY');
    return encrypt(msg, key);
  }
}
